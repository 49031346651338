import axios from 'axios';
import Cookies from 'js-cookie';

const URL = "https://ekp1hwfvq1.execute-api.ap-southeast-2.amazonaws.com/dev";

const ports = {
    Login: 3001,
    interest_categories: 3010,
    modules: 3011,
    payment_methods: 3012,
    roles: 3013,
    contacts: 3014,
    stores: 3050,
    venues: 3016,
    log: 3017,
    feedback: 3018,
    event_venues: 3019,
    employee_branch_event_venues: 3051,
    interests: 3021,
    client_interests: 3022,
    event_interests: 3023,
    inventories: 3024,
    orders: 3025,
    order_details: 3026,
    payments: 3027,
    permissions: 3028,
    product_descriptions: 3029,
    promotion_inventories: 3030,
    user_branches: 3031,
    user_roles: 3032,
    branches: 3033,
    categories: 3034,
    clients: 3035,
    employees: 3036,
    events: 3037,
    products: 3038,
    promotions: 3039,
    users: 3040,
    branch_event_venues: 3041
}

export async function makeRequest(endPoint, method, data, isMultipart) {
    return new Promise((resolve, reject) => {

        /*
            to-do:
            -- enviar el token JWT
            -- enviar el id del usuario que hace la petición
        */
        const userCookie = Cookies.get("loggued-user");
        if (!userCookie && endPoint !== 'Login') {
            console.log("no loggued");
        } else {
            let userInfo = userCookie && JSON.parse(userCookie)

            //var requestPort = ports[endPoint.split('/')[0]];
            if (!data)
                data = {};
            if (isMultipart)
                data = { ...data.data }
            console.log(data, isMultipart);
            data.userId = userInfo && userInfo.id;

            console.log(`${URL}/${endPoint}`, data);

            setTimeout(() => {
                axios({ method, url: `${URL}/${endPoint}`, data, headers: { 'authorization': (userInfo && userInfo.token) || '', "Content-Type": isMultipart ? "multipart/form-data" : "application/json" } })
                    .then((response) => {
                        console.log(response);
                        /*  
                        to-do: 
                            -- validar si la respuesta es token vencido y solicitar credenciales nuevamente
                            -- si es un error manejarlo para notificar al usuario
                        */
                        resolve(response);
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err);
                    })
            }, "30")
        }
    });
}


export function fixFormData(columnList, formData) {
    let newData = { ...formData };

    columnList.map(x => {
        if (formData[x.dbname]) {
            /*  if (x.type === 'boolean') {
                 if (formData[x.dbname]) {
                     try {
                         newData[x.dbname] = formData[x.dbname][0] === 'on';
                     } catch (ex) {
                         newData[x.dbname] = false;
                     }
                 } else {
                     newData[x.dbname] = false;
                 }
             } */
            if (x.type === 'datetime') {
                newData[x.dbname] = formData[x.dbname].replace('Z', '');
            }
        }
        return {};
    })
    return newData;
}