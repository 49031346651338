import { createTheme, responsiveFontSizes } from '@mui/material';

const borderWidth = 2;
const borderColor = "rgba(0, 0, 0, 0.13)";

const theme = createTheme({
  palette: {
    primary: {
      main: '#fc471e',
      logoz: 'blue',
      logoq: 'white',
      backcolor: "gainsboro",
      selectedMenuBackColor: "#e38084",
      headerBackColor: "#e38084",
      selectedMenuColor: "white"
    },
    secondary: {
      main: '#f50057',
      backcolor: "white",
    },
  },
  border: {
    borderColor: borderColor,
    borderWidth: borderWidth
  },
});

export default responsiveFontSizes(theme);