import { createTheme, responsiveFontSizes } from '@mui/material';

const borderWidth = 2;
const borderColor = "rgba(0, 0, 0, 0.13)";

const theme = createTheme({
    palette: {
      type: 'dark',
      primary: {
        main: '#ff5900',
        logoz: 'blue',
        logoq: 'white',
        backcolor: "gainsboro"
      },
      secondary: {
        main: '#f50057',
      },
    },
    border: {
      borderColor: borderColor,
      borderWidth: borderWidth
    },
  });

  export default responsiveFontSizes(theme);