import { createSlice } from '@reduxjs/toolkit';

import { makeRequest } from "../shared/common";

const endPoint = "orders";

export function fetchGetOrdersList(filters) {
  return async function fetchThunk(dispatch, getState) {
    dispatch(setIsLoading(true));
    await makeRequest(`${endPoint}/List`, 'post', { filters })
      .then((response) => {
        dispatch(setOrdersList(response.data));
        dispatch(setIsLoading(false));
      })
      .catch(err => {
        console.log('erre', err);
        dispatch(setLogError(err.message));
        dispatch(setIsLoading(false));
      });
  }
}

export function fetchDeleteOrders(ordersId) {
  return async function fetchThunk(dispatch, getState) {
    dispatch(setIsLoading(true));
    await makeRequest(`${endPoint}/Delete`, 'post', { id: ordersId })
      .then((response) => {
        dispatch(setIsLoading(false));
        return true;
      })
      .catch(err => {
        dispatch(setLogError(err.message));
        dispatch(setIsLoading(false));
      });
  }
}

export function fetchAddOrders(ordersData) {
  return async function fetchThunk(dispatch, getState) {
    dispatch(setIsLoading(true));
    await makeRequest(`${endPoint}/Create`, 'post', { data: ordersData })
      .then((response) => {
        dispatch(setIsLoading(false));
        return true;
      })
      .catch(err => {
        dispatch(setLogError(err.message));
        dispatch(setIsLoading(false));
      });
  }
}

export function fetchEditOrders(ordersData) {
  return async function fetchThunk(dispatch, getState) {
    dispatch(setIsLoading(true));
    await makeRequest(`${endPoint}/Update`, 'put', { data: ordersData })
      .then((response) => {
        dispatch(setIsLoading(false));
        return true;
      })
      .catch(err => {
        dispatch(setLogError(err.message));
        dispatch(setIsLoading(false));
      });
  }
}

export function fetchEditStatusOrders(ordersData) {
  return async function fetchThunk(dispatch, getState) {
    dispatch(setIsLoading(true));
    await makeRequest(`${endPoint}/Update`, 'put', { data: ordersData })
      .then((response) => {
        dispatch(setIsLoading(false));
        dispatch(updateOrderStatus(ordersData));
        return true;
      })
      .catch(err => {
        dispatch(setLogError(err.message));
        dispatch(setIsLoading(false));
      });
  }
}

export async function fetchGetPendingOrders(dispatch, eventId, branchId, lastId) {
  dispatch(setIsLoading(true));
  return await makeRequest(`${endPoint}/GetPendingOrders`, 'post', { eventId, branchId, lastId })
    .then((response) => {
      if (lastId) {
        if (response.data.data.ordersList.length > 0)
          dispatch(appendPendingOrders(response.data));
      } else
        dispatch(setPendingOrders(response.data));
      dispatch(setIsLoading(false));
      return response.data.data.ordersList;
    })
    .catch(err => {
      console.log('erre', err);
      dispatch(setLogError(err.message));
      dispatch(setIsLoading(false));
    });
}

export const ordersSlice = createSlice({
  name: 'orders',
  initialState: {
    ordersList: [],
    page: 0,
    totalItems: 0,
    itemsPerPage: 20,
    isLoading: false,
    errorMessage: null,
    filters: {},
    order: null,
    pendingOrders: []
  },
  reducers: {
    setOrdersList: (state, action) => {
      state.ordersList = action.payload.data.ordersList;
      if (action.payload.data.qty) {
        state.totalItems = action.payload.data.qty;
      }
    },
    setPendingOrders: (state, action) => {
      state.pendingOrders = action.payload.data.ordersList;
    },
    appendPendingOrders: (state, action) => {
      const newPO = [...state.pendingOrders];
      console.log(newPO);
      newPO.push(...action.payload.data.ordersList);
      state.pendingOrders = newPO;
    },
    updateOrderStatus: (state, action) => {
      const newPO = [...state.pendingOrders];
      const updateStatus = newPO.find((po) => po.id === action.payload.id);
      if (updateStatus) {
        updateStatus.status = action.payload.status;
        updateStatus.employee_id = action.payload.employee_id;
      }
      state.pendingOrders = newPO;
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setOrder: (state, action) => {
      state.order = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setLogError: (state, action) => {
      state.errorMessage = action.payload;
    },
  }
})

export const {
  setIsLoading,
  setLogError,
  setOrdersList,
  setItemsPerPage,
  setFilters,
  setPage,
  setOrder,
  setPendingOrders,
  appendPendingOrders,
  updateOrderStatus
} = ordersSlice.actions;

export default ordersSlice.reducer