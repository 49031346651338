import { createSlice } from '@reduxjs/toolkit';

import { makeRequest } from "../shared/common";

const endPoint = "stores";

export function fetchGetStoresList(filters) {
  return async function fetchThunk(dispatch, getState) {
    dispatch(setIsLoading(true));
    await makeRequest(`${endPoint}/List`, 'post', { filters })
      .then((response) => {
        dispatch(setStoresList(response.data));
        dispatch(setIsLoading(false));
      })
      .catch(err => {
        console.log('erre', err);
        dispatch(setLogError(err.message));
        dispatch(setIsLoading(false));
      });
  }
}

export function fetchDeleteStores(storesId) {
  return async function fetchThunk(dispatch, getState) {
    dispatch(setIsLoading(true));
    await makeRequest(`${endPoint}/Delete`, 'post', { id: storesId })
      .then((response) => {
        dispatch(setIsLoading(false));
        return true;
      })
      .catch(err => {
        dispatch(setLogError(err.message));
        dispatch(setIsLoading(false));
      });
  }
}

export function fetchAddStores(storesData) {
  return async function fetchThunk(dispatch, getState) {
    dispatch(setIsLoading(true));
    await makeRequest(`${endPoint}/Create`, 'post', { data: storesData })
      .then((response) => {
        dispatch(setIsLoading(false));
        return true;
      })
      .catch(err => {
        dispatch(setLogError(err.message));
        dispatch(setIsLoading(false));
      });
  }
}

export function fetchEditStores(storesData) {
  return async function fetchThunk(dispatch, getState) {
    dispatch(setIsLoading(true));
    await makeRequest(`${endPoint}/Update`, 'put', { data: storesData })
      .then((response) => {
        dispatch(setIsLoading(false));
        return true;
      })
      .catch(err => {
        dispatch(setLogError(err.message));
        dispatch(setIsLoading(false));
      });
  }
}

export const storesSlice = createSlice({
  name: 'stores',
  initialState: {
    storesList: [],
    page: 0,
    totalItems: 0,
    itemsPerPage: 20,
    isLoading: false,
    errorMessage: null,
    filters: {},
    order: null
  },
  reducers: {
    setStoresList: (state, action) => {
      state.storesList = action.payload.data.storesList;
      if (action.payload.data.qty) {
        state.totalItems = action.payload.data.qty;
      }
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setOrder: (state, action) => {
      state.order = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setLogError: (state, action) => {
      state.errorMessage = action.payload;
    },
  }
})

export const {
  setIsLoading,
  setLogError,
  setStoresList,
  setItemsPerPage,
  setFilters,
  setPage,
  setOrder
} = storesSlice.actions;

export default storesSlice.reducer