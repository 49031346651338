import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import { makeRequest } from "../shared/common";

const endPoint = "Login";

export function fetchLogin(user, pass) {
  return async function fetchThunk(dispatch, getState) {
    dispatch(setIsLoading(true));
    await makeRequest(`${endPoint}`, 'post', { email: user, password: pass })
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data && response.data.statusCode === 200) {
            dispatch(setLogIn(response.data));
            dispatch(fetchGetUserBranches(response.data.userId));
            //dispatch(fetchGetMenu(response.data.id));
            dispatch(setIsLoading(false));
          } else {
            await Promise.all([dispatch(setLogError({ code: response.data.statusCode, msg: response.data.body }))]);
            dispatch(setIsLoading(false));
          }
        } else {
          await Promise.all([dispatch(setLogError({ code: response.status, msg: `Unknokn Error, status: ${response.status}` }))]);
          dispatch(setIsLoading(false));
        }
      })
      .catch(err => {
        dispatch(setLogError({ code: 500, msg: err.message }));
        dispatch(setIsLoading(false));
      });
  }
}

export function fetchGetUserBranches(userId) {
  return async function fetchThunk(dispatch, getState) {
    dispatch(setIsLoading(true));
    await makeRequest(`user_branches/getBranchesByUser`, 'post', { filters: { userId } })
      .then((response) => {
        if (response.data.statusCode === 200) {
          dispatch(setUserBranches(response.data.data.user_branchesList));
        }

        dispatch(setIsLoading(false));
      })
      .catch(err => {
        dispatch(setLogError({ code: 500, msg: err.message }));
        dispatch(setIsLoading(false));
      });
  }
}

/* export function fetchGetMenu(userId) {
    return async function fetchThunk(dispatch, getState) {
    dispatch(setIsLoading(true));
      await makeRequest(`${endPoint}/GetMenu`, 'post', { userId })
      .then((response) => {
          response.userId = userId;
          dispatch(setMenu(response));
      })
      .catch(err => {
        dispatch(setLogError({ code: 500, msg: err.message}));
        dispatch(setIsLoading(false));
      });
    }
  } */

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    loggued: false,
    status: 'none',
    isLoading: false,
    user: {},
    mustLogin: true,
    userBranches: null,
    activeEvent: null,
    activeBranch: 'none',
    activeRole: 'none'
  },
  reducers: {
    setLogIn: (state, action) => {
      state.loggued = action.payload.statusCode === 200;
      state.status = 'ok';
      state.user = {
        id: action.payload.userId,
        username: action.payload.username,
        token: action.payload.token,
        clientId: action.payload.clientId,
        roles: action.payload.userRol,
        storeId: action.payload.storeId
      };
      state.mustLogin = false;
      Cookies.set("loggued-user", JSON.stringify(state.user), {
        expires: 1,
      });
    },
    setLogOut: (state) => {
      state.loggued = false;
      state.status = 'none';
      state.user = {};
      state.mustLogin = true;
      state.userBranches = null;
      state.activeEvent = null;
      state.activeBranch = 'none';
      state.activeRole = 'none';
      Cookies.remove("loggued-user");
    },
    setLogError: (state, action) => {
      state.loggued = false;
      state.status = action.payload.code === 400 ? "invalidUserOrPassword" : "internalError";
      state.message = action.payload.msg;
      state.user = {};
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setFromCookies: (state, action) => {
      const userCookie = JSON.parse(Cookies.get("loggued-user"));
      state.loggued = true;
      state.user = userCookie;
      state.userBranches = userCookie.branches.map((x, idx) => { return { ...x, selected: idx === 0 } });
      const activeBranch = userCookie.branches.length > 0 && userCookie.branches[0].branch_id;
      if (activeBranch) {
        const aRole = userCookie.roles && userCookie.roles.find((r) => r.branch_id == activeBranch);
        state.activeRole = (aRole && aRole.rol) || 'none';
      } else {
        state.activeRole = 'none';
      }
    },
    setMustLogin: (state, action) => {
      state.mustLogin = action.payload;
    },
    setUserBranches: (state, action) => {
      const logguedCookie = Cookies.get("loggued-user");
      if (logguedCookie) {
        const jsonLoggued = JSON.parse(logguedCookie);
        jsonLoggued.branches = action.payload;
        Cookies.set("loggued-user", JSON.stringify(jsonLoggued), {
          expires: 1,
        });
      }

      state.userBranches = action.payload.map((ub, idx) => { return { ...ub, selected: idx === 0 } });
      const activeBranch = action.payload.length > 0 && action.payload[0].branch_id;
      if (activeBranch) {
        const aRole = state.user.roles && state.user.roles.find((r) => r.branch_id == activeBranch);
        state.activeRole = (aRole && aRole.rol) || 'none';
      } else {
        state.activeRole = 'none';
      }
    },
    setActiveBranch: (state, action) => {
      state.userBranches = state.userBranches.map((ub) => { return { ...ub, selected: ub.branch_id === action.payload } });
      var aRole = state.user.roles && state.user.roles.find((r) => r.branch_id == action.payload && state.activeRole == r.rol);
      if (!aRole)
        aRole = state.user.roles && state.user.roles.find((r) => r.branch_id == action.payload);
      state.activeRole = (aRole && aRole.id) || 'none';
      state.activeRole = state.user && state.user.roles && state.user.roles.find((r) => r.branch_id == action.payload && state.activeRole == r.rol) || state.user && state.user.roles && state.user.roles.length > 0 && state.user.roles[0].rol || 'none';
    },
    setActiveEvent: (state, action) => {
      state.activeEvent = action.payload;
    },
    setActiveRole: (state, action) => {
      state.activeRole = action.payload;
    }
  }
})

export const {
  setLogIn,
  setLogOut,
  setLogError,
  setIsLoading,
  incrementByAmount,
  setFromCookies,
  setMustLogin,
  setUserBranches,
  setActiveBranch,
  setActiveEvent,
  setActiveRole
} = loginSlice.actions;

export default loginSlice.reducer