import { createSlice } from '@reduxjs/toolkit';
import { setMustLogin } from './loginSlice';
import { makeRequest } from "../shared/common";
import { useDispatch } from 'react-redux';

const endPoint = "log";

export function fetchGetLogList(filters) {
  return async function fetchThunk(dispatch, getState) {
    dispatch(setIsLoading(true));
    await makeRequest(`${endPoint}/List`, 'post', { filters })
      .then((response) => {
        dispatch(setLogList(response.data));
        dispatch(setIsLoading(false));
      })
      .catch(err => {
        console.log('erre', err);
        dispatch(setLogError(err.message));
        dispatch(setIsLoading(false));
      });
  }
}

export function fetchDeleteLog(logId) {
  return async function fetchThunk(dispatch, getState) {
    dispatch(setIsLoading(true));
    await makeRequest(`${endPoint}/Delete`, 'post', { id: logId })
      .then((response) => {
        dispatch(setIsLoading(false));
        return true;
      })
      .catch(err => {
        dispatch(setLogError(err.message));
        dispatch(setIsLoading(false));
      });
  }
}

export function fetchAddLog(logData) {
  return async function fetchThunk(dispatch, getState) {
    dispatch(setIsLoading(true));
    await makeRequest(`${endPoint}/Create`, 'post', { data: logData })
      .then((response) => {
        dispatch(setIsLoading(false));
        return true;
      })
      .catch(err => {
        dispatch(setLogError(err.message));
        dispatch(setIsLoading(false));
      });
  }
}

export function fetchEditLog(logData) {
  return async function fetchThunk(dispatch, getState) {
    dispatch(setIsLoading(true));
    await makeRequest(`${endPoint}/Update`, 'put', { data: logData })
      .then((response) => {
        dispatch(setIsLoading(false));
        return true;
      })
      .catch(err => {
        dispatch(setLogError(err.message));
        dispatch(setIsLoading(false));
      });
  }
}

export const logSlice = createSlice({
  name: 'log',
  initialState: {
    logList: [],
    page: 0,
    totalItems: 0,
    itemsPerPage: 20,
    isLoading: false,
    errorMessage: null,
    filters: {},
    order: null
  },
  reducers: {
    setLogList: (state, action) => {
      state.logList = action.payload.data.logList;
      if (action.payload.data.qty) {
        state.totalItems = action.payload.data.qty;
      }
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setOrder: (state, action) => {
      state.order = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setLogError: (state, action) => {
      state.errorMessage = action.payload;
    },
  }
})

export const {
  setIsLoading,
  setLogError,
  setLogList,
  setItemsPerPage,
  setFilters,
  setPage,
  setOrder
} = logSlice.actions;

export default logSlice.reducer