import { configureStore } from '@reduxjs/toolkit';
import loginSlice from './loginSlice';
import navigationSlice from './navigationSlice';

import interest_categoriesSlice from './interest_categoriesSlice';
import modulesSlice from './modulesSlice';
import payment_methodsSlice from './payment_methodsSlice';
import rolesSlice from './rolesSlice';
import contactsSlice from './contactsSlice';
import storesSlice from './storesSlice';
import venuesSlice from './venuesSlice';
import logSlice from './logSlice';
import clientsSlice from './clientsSlice';
import eventsSlice from './eventsSlice';
import feedbackSlice from './feedbackSlice';
import employeesSlice from './employeesSlice';
import branchesSlice from './branchesSlice';
import event_venuesSlice from './event_venuesSlice';
import branch_event_venuesSlice from './branch_event_venuesSlice';
import employee_branch_event_venuesSlice from './employee_branch_event_venuesSlice';
import categoriesSlice from './categoriesSlice';
import interestsSlice from './interestsSlice';
import client_interestsSlice from './client_interestsSlice';
import event_interestsSlice from './event_interestsSlice';
import productsSlice from './productsSlice';
import inventoriesSlice from './inventoriesSlice';
import ordersSlice from './ordersSlice';
import order_detailsSlice from './order_detailsSlice';
import paymentsSlice from './paymentsSlice';
import permissionsSlice from './permissionsSlice';
import product_descriptionsSlice from './product_descriptionsSlice';
import promotionsSlice from './promotionsSlice';
import promotion_inventoriesSlice from './promotion_inventoriesSlice';
import user_branchesSlice from './user_branchesSlice';
import user_rolesSlice from './user_rolesSlice';
import userbranchesSlice from './userbranchesSlice';
import usersSlice from './usersSlice';

export default configureStore({
  reducer: {
    login: loginSlice,
    navigation: navigationSlice,
    interest_categories: interest_categoriesSlice,
    modules: modulesSlice,
    payment_methods: payment_methodsSlice,
    roles: rolesSlice,
    contacts: contactsSlice,
    stores: storesSlice,
    venues: venuesSlice,
    log: logSlice,
    clients: clientsSlice,
    events: eventsSlice,
    feedback: feedbackSlice,
    employees: employeesSlice,
    branches: branchesSlice,
    event_venues: event_venuesSlice,
    branch_event_venues: branch_event_venuesSlice,
    employee_branch_event_venues: employee_branch_event_venuesSlice,
    categories: categoriesSlice,
    interests: interestsSlice,
    client_interests: client_interestsSlice,
    event_interests: event_interestsSlice,
    products: productsSlice,
    inventories: inventoriesSlice,
    orders: ordersSlice,
    order_details: order_detailsSlice,
    payments: paymentsSlice,
    permissions: permissionsSlice,
    product_descriptions: product_descriptionsSlice,
    promotions: promotionsSlice,
    promotion_inventories: promotion_inventoriesSlice,
    user_branches: user_branchesSlice,
    user_roles: user_rolesSlice,
    userbranches: userbranchesSlice,
    users: usersSlice
  }
})