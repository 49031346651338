import { createSlice } from '@reduxjs/toolkit';

import { makeRequest } from "../shared/common";

const endPoint = "branch_event_venues";

export function fetchGetBranch_event_venuesList(filters) {
  return async function fetchThunk(dispatch, getState) {
    dispatch(setIsLoading(true));
    await makeRequest(`${endPoint}/List`, 'post', { filters })
      .then((response) => {
        dispatch(setBranch_event_venuesList(response.data));
        dispatch(setIsLoading(false));
      })
      .catch(err => {
        console.log('erre', err);
        dispatch(setLogError(err.message));
        dispatch(setIsLoading(false));
      });
  }
}

export function fetchGetBranch_event_venuesListByUser(userId) {
  return async function fetchThunk(dispatch, getState) {
    dispatch(setIsLoading(true));
    await makeRequest(`${endPoint}/ListByUser`, 'post', { filters: { userId } })
      .then((response) => {
        dispatch(setBranch_event_venuesList(response.data));
        dispatch(setIsLoading(false));
      })
      .catch(err => {
        console.log('erre', err);
        dispatch(setLogError(err.message));
        dispatch(setIsLoading(false));
      });
  }
}

export function fetchDeleteBranch_event_venues(branch_event_venuesId) {
  return async function fetchThunk(dispatch, getState) {
    dispatch(setIsLoading(true));
    await makeRequest(`${endPoint}/Delete`, 'post', { id: branch_event_venuesId })
      .then((response) => {
        dispatch(setIsLoading(false));
        return true;
      })
      .catch(err => {
        dispatch(setLogError(err.message));
        dispatch(setIsLoading(false));
      });
  }
}

export function fetchAddBranch_event_venues(branch_event_venuesData) {
  return async function fetchThunk(dispatch, getState) {
    dispatch(setIsLoading(true));
    await makeRequest(`${endPoint}/Create`, 'post', { data: branch_event_venuesData })
      .then((response) => {
        dispatch(setIsLoading(false));
        return true;
      })
      .catch(err => {
        dispatch(setLogError(err.message));
        dispatch(setIsLoading(false));
      });
  }
}

export function fetchEditBranch_event_venues(branch_event_venuesData) {
  return async function fetchThunk(dispatch, getState) {
    dispatch(setIsLoading(true));
    await makeRequest(`${endPoint}/Update`, 'put', { data: branch_event_venuesData })
      .then((response) => {
        dispatch(setIsLoading(false));
        return true;
      })
      .catch(err => {
        dispatch(setLogError(err.message));
        dispatch(setIsLoading(false));
      });
  }
}

export const branch_event_venuesSlice = createSlice({
  name: 'branch_event_venues',
  initialState: {
    branch_event_venuesList: [],
    page: 0,
    totalItems: 0,
    itemsPerPage: 20,
    isLoading: false,
    errorMessage: null,
    filters: {},
    order: null
  },
  reducers: {
    setBranch_event_venuesList: (state, action) => {
      state.branch_event_venuesList = action.payload.data.branch_event_venuesList;
      if (action.payload.data.qty) {
        state.totalItems = action.payload.data.qty;
      }
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setOrder: (state, action) => {
      state.order = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setLogError: (state, action) => {
      state.errorMessage = action.payload;
    },
  }
})

export const {
  setIsLoading,
  setLogError,
  setBranch_event_venuesList,
  setItemsPerPage,
  setFilters,
  setPage,
  setOrder
} = branch_event_venuesSlice.actions;

export default branch_event_venuesSlice.reducer