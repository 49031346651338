import { createSlice } from '@reduxjs/toolkit';

import { makeRequest } from "../shared/common";

const endPoint = "modules";

export function fetchGetModulesList(filters) {
    return async function fetchThunk(dispatch, getState) {
      dispatch(setIsLoading(true));
      await makeRequest(`${endPoint}/List`, 'post', { filters })
      .then((response) => {
        dispatch(setModulesList(response.data));
        dispatch(setIsLoading(false));
      })
      .catch(err => {
        console.log('erre', err);
          dispatch(setLogError(err.message));
          dispatch(setIsLoading(false));
      });
    }
  }

  export function fetchDeleteModules(modulesId) {
      return async function fetchThunk(dispatch, getState) {
          dispatch(setIsLoading(true));
        await makeRequest(`${endPoint}/Delete`, 'post',  {id: modulesId} )
        .then((response) => {
          dispatch(setIsLoading(false));
          return true;
        })
        .catch(err => {
            dispatch(setLogError(err.message));
            dispatch(setIsLoading(false));
        });
      }
    }

  export function fetchAddModules(modulesData) {
    return async function fetchThunk(dispatch, getState) {
        dispatch(setIsLoading(true));
      await makeRequest(`${endPoint}/Create`, 'post', { data: modulesData })
      .then((response) => {
        dispatch(setIsLoading(false));
        return true;
      })
      .catch(err => {
          dispatch(setLogError(err.message));
          dispatch(setIsLoading(false));
      });
    }
  }

  export function fetchEditModules(modulesData) {
    return async function fetchThunk(dispatch, getState) {
        dispatch(setIsLoading(true));
      await makeRequest(`${endPoint}/Update`, 'put', { data: modulesData })
      .then((response) => {
        dispatch(setIsLoading(false));
        return true;
      })
      .catch(err => {
          dispatch(setLogError(err.message));
          dispatch(setIsLoading(false));
      });
    }
  } 

export const modulesSlice = createSlice({
    name: 'modules',
    initialState: {
      modulesList: [],
      page:0,
      totalItems: 0,
      itemsPerPage: 20,
      isLoading: false,
      errorMessage: null,
      filters: {},
      order: null
    },
    reducers: {
        setModulesList: (state, action) => {
            state.modulesList = action.payload.data.modulesList;
            if(action.payload.data.qty){
              state.totalItems = action.payload.data.qty;
            }
        },
        setItemsPerPage: (state, action) => {
          state.itemsPerPage = action.payload;
        },
        setFilters: (state, action) => {
          state.filters = action.payload;
        },
        setPage: (state, action) => {
          state.page = action.payload;
        },
        setOrder: (state, action) => {
          state.order = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setLogError: (state, action) => {
            state.errorMessage = action.payload;
        },
    }
  })
  
  export const { 
    setIsLoading,
    setLogError,
    setModulesList,
    setItemsPerPage,
    setFilters,
    setPage,
    setOrder
  } = modulesSlice.actions;
  
  export default modulesSlice.reducer