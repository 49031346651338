import { createSlice } from '@reduxjs/toolkit';

import { makeRequest } from "../shared/common";

const endPoint = "user_roles";

export function fetchGetUser_rolesList(filters) {
    return async function fetchThunk(dispatch, getState) {
      dispatch(setIsLoading(true));
      await makeRequest(`${endPoint}/List`, 'post', { filters })
      .then((response) => {
        dispatch(setUser_rolesList(response.data));
        dispatch(setIsLoading(false));
      })
      .catch(err => {
        console.log('erre', err);
          dispatch(setLogError(err.message));
          dispatch(setIsLoading(false));
      });
    }
  }

  export function fetchDeleteUser_roles(user_rolesId) {
      return async function fetchThunk(dispatch, getState) {
          dispatch(setIsLoading(true));
        await makeRequest(`${endPoint}/Delete`, 'post',  {id: user_rolesId} )
        .then((response) => {
          dispatch(setIsLoading(false));
          return true;
        })
        .catch(err => {
            dispatch(setLogError(err.message));
            dispatch(setIsLoading(false));
        });
      }
    }

  export function fetchAddUser_roles(user_rolesData) {
    return async function fetchThunk(dispatch, getState) {
        dispatch(setIsLoading(true));
      await makeRequest(`${endPoint}/Create`, 'post', { data: user_rolesData })
      .then((response) => {
        dispatch(setIsLoading(false));
        return true;
      })
      .catch(err => {
          dispatch(setLogError(err.message));
          dispatch(setIsLoading(false));
      });
    }
  }

  export function fetchEditUser_roles(user_rolesData) {
    return async function fetchThunk(dispatch, getState) {
        dispatch(setIsLoading(true));
      await makeRequest(`${endPoint}/Update`, 'put', { data: user_rolesData })
      .then((response) => {
        dispatch(setIsLoading(false));
        return true;
      })
      .catch(err => {
          dispatch(setLogError(err.message));
          dispatch(setIsLoading(false));
      });
    }
  } 

export const user_rolesSlice = createSlice({
    name: 'user_roles',
    initialState: {
      user_rolesList: [],
      page:0,
      totalItems: 0,
      itemsPerPage: 20,
      isLoading: false,
      errorMessage: null,
      filters: {},
      order: null
    },
    reducers: {
        setUser_rolesList: (state, action) => {
            state.user_rolesList = action.payload.data.user_rolesList;
            if(action.payload.data.qty){
              state.totalItems = action.payload.data.qty;
            }
        },
        setItemsPerPage: (state, action) => {
          state.itemsPerPage = action.payload;
        },
        setFilters: (state, action) => {
          state.filters = action.payload;
        },
        setPage: (state, action) => {
          state.page = action.payload;
        },
        setOrder: (state, action) => {
          state.order = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setLogError: (state, action) => {
            state.errorMessage = action.payload;
        },
    }
  })
  
  export const { 
    setIsLoading,
    setLogError,
    setUser_rolesList,
    setItemsPerPage,
    setFilters,
    setPage,
    setOrder
  } = user_rolesSlice.actions;
  
  export default user_rolesSlice.reducer