import { makeRequest } from "../shared/common";
import { createSlice } from '@reduxjs/toolkit';



const endPoint = "employee_branch_event_venues";

export function fetchGetEmployee_branch_event_venuesList(filters) {
  return async function fetchThunk(dispatch, getState) {
    dispatch(setIsLoading(true));
    await makeRequest(`${endPoint}/List`, 'post', { filters })
      .then((response) => {
        dispatch(setEmployee_branch_event_venuesList(response.data));
        dispatch(setIsLoading(false));
      })
      .catch(err => {
        console.log('erre', err);
        dispatch(setLogError(err.message));
        dispatch(setIsLoading(false));
      });
  }
}

export function fetchGetActiveEmployee_branch_event_venuesList(filters) {
  return async function fetchThunk(dispatch, getState) {
    dispatch(setIsLoading(true));
    await makeRequest(`${endPoint}/GetActiveEmployeesList`, 'post', { filters })
      .then((response) => {
        dispatch(setActiveEmployees(response.data.data.employee_branch_event_venuesList));
        dispatch(setIsLoading(false));
      })
      .catch(err => {
        console.log('erre', err);
        dispatch(setLogError(err.message));
        dispatch(setIsLoading(false));
      });
  }
}

export async function fetchReturnActiveEmployee_branch_event_venuesList(filters) {
  return await makeRequest(`${endPoint}/GetActiveEmployeesList`, 'post', { filters })
    .then((response) => {
      return response.data.data.employee_branch_event_venuesList;
    })
    .catch(err => {
      console.log('erre', err);
    });
}

export function fetchDeleteEmployee_branch_event_venues(employee_branch_event_venuesId) {
  return async function fetchThunk(dispatch, getState) {
    dispatch(setIsLoading(true));
    await makeRequest(`${endPoint}/Delete`, 'post', { id: employee_branch_event_venuesId })
      .then((response) => {
        dispatch(setIsLoading(false));
        return true;
      })
      .catch(err => {
        dispatch(setLogError(err.message));
        dispatch(setIsLoading(false));
      });
  }
}

export async function fetchAddEmployee_branch_event_venues(employee_branch_event_venuesData, dispatch) {
  dispatch(setIsLoading(true));
  return await makeRequest(`${endPoint}/Create`, 'post', { data: employee_branch_event_venuesData })
    .then((response) => {
      dispatch(setIsLoading(false));
      return response;
    })
    .catch(err => {
      console.log('err', err);
      dispatch(setLogError(err.message));
      dispatch(setIsLoading(false));
      return err;
    });
}

export function fetchEditEmployee_branch_event_venues(employee_branch_event_venuesData) {
  return async function fetchThunk(dispatch, getState) {
    dispatch(setIsLoading(true));
    await makeRequest(`${endPoint}/Update`, 'put', { data: employee_branch_event_venuesData })
      .then((response) => {
        dispatch(setIsLoading(false));
        return true;
      })
      .catch(err => {
        dispatch(setLogError(err.message));
        dispatch(setIsLoading(false));
      });
  }
}


export const employee_branch_event_venuesSlice = createSlice({
  name: 'employee_branch_event_venues',
  initialState: {
    employee_branch_event_venuesList: [],
    page: 0,
    totalItems: 0,
    itemsPerPage: 20,
    isLoading: false,
    errorMessage: null,
    filters: {},
    order: null,
    activeEmployees: []
  },
  reducers: {
    setEmployee_branch_event_venuesList: (state, action) => {
      state.employee_branch_event_venuesList = action.payload.data.employee_branch_event_venuesList;
      if (action.payload.data.qty) {
        state.totalItems = action.payload.data.qty;
      }
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setOrder: (state, action) => {
      state.order = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setLogError: (state, action) => {
      state.errorMessage = action.payload;
    },
    setActiveEmployees: (state, action) => {
      state.activeEmployees = action.payload;
    },
  }
})

export const {
  setIsLoading,
  setLogError,
  setEmployee_branch_event_venuesList,
  setItemsPerPage,
  setFilters,
  setPage,
  setOrder,
  setActiveEmployees
} = employee_branch_event_venuesSlice.actions;

export default employee_branch_event_venuesSlice.reducer