import { createSlice } from '@reduxjs/toolkit';

import { makeRequest } from "../shared/common";

const endPoint = "interests";

export function fetchGetInterestsList(filters) {
    return async function fetchThunk(dispatch, getState) {
      dispatch(setIsLoading(true));
      await makeRequest(`${endPoint}/List`, 'post', { filters })
      .then((response) => {
        dispatch(setInterestsList(response.data));
        dispatch(setIsLoading(false));
      })
      .catch(err => {
        console.log('erre', err);
          dispatch(setLogError(err.message));
          dispatch(setIsLoading(false));
      });
    }
  }

  export function fetchDeleteInterests(interestsId) {
      return async function fetchThunk(dispatch, getState) {
          dispatch(setIsLoading(true));
        await makeRequest(`${endPoint}/Delete`, 'post',  {id: interestsId} )
        .then((response) => {
          dispatch(setIsLoading(false));
          return true;
        })
        .catch(err => {
            dispatch(setLogError(err.message));
            dispatch(setIsLoading(false));
        });
      }
    }

  export function fetchAddInterests(interestsData) {
    return async function fetchThunk(dispatch, getState) {
        dispatch(setIsLoading(true));
      await makeRequest(`${endPoint}/Create`, 'post', { data: interestsData })
      .then((response) => {
        dispatch(setIsLoading(false));
        return true;
      })
      .catch(err => {
          dispatch(setLogError(err.message));
          dispatch(setIsLoading(false));
      });
    }
  }

  export function fetchEditInterests(interestsData) {
    return async function fetchThunk(dispatch, getState) {
        dispatch(setIsLoading(true));
      await makeRequest(`${endPoint}/Update`, 'put', { data: interestsData })
      .then((response) => {
        dispatch(setIsLoading(false));
        return true;
      })
      .catch(err => {
          dispatch(setLogError(err.message));
          dispatch(setIsLoading(false));
      });
    }
  } 

export const interestsSlice = createSlice({
    name: 'interests',
    initialState: {
      interestsList: [],
      page:0,
      totalItems: 0,
      itemsPerPage: 20,
      isLoading: false,
      errorMessage: null,
      filters: {},
      order: null
    },
    reducers: {
        setInterestsList: (state, action) => {
            state.interestsList = action.payload.data.interestsList;
            if(action.payload.data.qty){
              state.totalItems = action.payload.data.qty;
            }
        },
        setItemsPerPage: (state, action) => {
          state.itemsPerPage = action.payload;
        },
        setFilters: (state, action) => {
          state.filters = action.payload;
        },
        setPage: (state, action) => {
          state.page = action.payload;
        },
        setOrder: (state, action) => {
          state.order = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setLogError: (state, action) => {
            state.errorMessage = action.payload;
        },
    }
  })
  
  export const { 
    setIsLoading,
    setLogError,
    setInterestsList,
    setItemsPerPage,
    setFilters,
    setPage,
    setOrder
  } = interestsSlice.actions;
  
  export default interestsSlice.reducer