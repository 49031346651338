import React, { Fragment, Suspense, lazy, useState, useEffect } from "react";
import { ThemeProvider, StyledEngineProvider, CssBaseline } from "@mui/material";
import { BrowserRouter } from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux';

import Cookies from "js-cookie";

import themeLight from "./themeLight";
import themeDark from "./themeDark";
import GlobalStyles from "./GlobalStyles";

import { useTranslation } from "react-i18next";

import { setFromCookies } from "./reducer/loginSlice";

const LoggedInComponent = lazy(() => import("./loggedIn/Main"));
const LoggedOutComponent = lazy(() => import("./loggedOut/Main"));
const VendorViewComponent = lazy(() => import("./loggedIn/vendorView/Main"));
const AdminViewComponent = lazy(() => import("./loggedIn/adminView/Main"));

String.prototype.toHHMMSS = function () {
  var sec_num = parseInt(this, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours < 10) { hours = "0" + hours; }
  if (minutes < 10) { minutes = "0" + minutes; }
  if (seconds < 10) { seconds = "0" + seconds; }
  return hours + ':' + minutes + ':' + seconds;
}

function App() {

  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();



  var isLogged = useSelector((state) => state.login.loggued);
  var userRoles = useSelector((state) => state.login.activeRole);

  if (!isLogged && Cookies.get("loggued-user") !== undefined) {
    isLogged = true;
    dispatch(setFromCookies());
  }
  const [themeType, setThemeType] = useState('light');



  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themeType === 'light' ? themeLight : themeDark}>
          <CssBaseline />
          <GlobalStyles />
          <Suspense fallback={<Fragment />}>
            {!isLogged &&
              <LoggedOutComponent tr={t} />
            }
            {isLogged && userRoles && userRoles === 'Super Admin' &&
              <LoggedInComponent tr={t} />
            }
            {isLogged && userRoles && userRoles === 'Store Admin' &&
              <AdminViewComponent tr={t} />
            }
            {isLogged && userRoles && userRoles === 'Vendor' &&
              <VendorViewComponent tr={t} />
            }
          </Suspense>
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  );
}

export default App;
