import { createSlice } from '@reduxjs/toolkit';

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {
    currentModule: "DashBoard",
    autoAssign: false,
    showScanner: false,
    showDelivered: false,
    showEventProducts: false
  },
  reducers: {
    setCurrentModule: (state, action) => {
      state.currentModule = action.payload;
    },
    setAutoAssign: (state, action) => {
      state.autoAssign = action.payload;
    },
    setShowScanner: (state) => {
      state.showScanner = !state.showScanner;
    },
    setShowDelivered: (state) => {
      state.showDelivered = !state.showDelivered;
    },
    setShowEventProducts: (state) => {
      console.log(state.showEventProducts);
      state.showEventProducts = !state.showEventProducts;
    }
  }
})

export const {
  setCurrentModule,
  setAutoAssign,
  setShowScanner,
  setShowDelivered,
  setShowEventProducts
} = navigationSlice.actions;

export default navigationSlice.reducer