import { createSlice } from '@reduxjs/toolkit';

import { makeRequest } from "../shared/common";

const endPoint = "userbranches";

export function fetchGetUserbranchesList(filters) {
    return async function fetchThunk(dispatch, getState) {
      dispatch(setIsLoading(true));
      await makeRequest(`${endPoint}/List`, 'post', { filters })
      .then((response) => {
        dispatch(setUserbranchesList(response.data));
        dispatch(setIsLoading(false));
      })
      .catch(err => {
        console.log('erre', err);
          dispatch(setLogError(err.message));
          dispatch(setIsLoading(false));
      });
    }
  }

  export function fetchDeleteUserbranches(userbranchesId) {
      return async function fetchThunk(dispatch, getState) {
          dispatch(setIsLoading(true));
        await makeRequest(`${endPoint}/Delete`, 'post',  {id: userbranchesId} )
        .then((response) => {
          dispatch(setIsLoading(false));
          return true;
        })
        .catch(err => {
            dispatch(setLogError(err.message));
            dispatch(setIsLoading(false));
        });
      }
    }

  export function fetchAddUserbranches(userbranchesData) {
    return async function fetchThunk(dispatch, getState) {
        dispatch(setIsLoading(true));
      await makeRequest(`${endPoint}/Create`, 'post', { data: userbranchesData })
      .then((response) => {
        dispatch(setIsLoading(false));
        return true;
      })
      .catch(err => {
          dispatch(setLogError(err.message));
          dispatch(setIsLoading(false));
      });
    }
  }

  export function fetchEditUserbranches(userbranchesData) {
    return async function fetchThunk(dispatch, getState) {
        dispatch(setIsLoading(true));
      await makeRequest(`${endPoint}/Update`, 'put', { data: userbranchesData })
      .then((response) => {
        dispatch(setIsLoading(false));
        return true;
      })
      .catch(err => {
          dispatch(setLogError(err.message));
          dispatch(setIsLoading(false));
      });
    }
  } 

export const userbranchesSlice = createSlice({
    name: 'userbranches',
    initialState: {
      userbranchesList: [],
      page:0,
      totalItems: 0,
      itemsPerPage: 20,
      isLoading: false,
      errorMessage: null,
      filters: {}
    },
    reducers: {
        setUserbranchesList: (state, action) => {
            state.userbranchesList = action.payload.data.userbranchesList;
            if(action.payload.data.qty){
              state.totalItems = action.payload.data.qty;
            }
        },
        setItemsPerPage: (state, action) => {
          state.itemsPerPage = action.payload;
        },
        setFilters: (state, action) => {
          state.filters = action.payload;
        },
        setPage: (state, action) => {
          state.page = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setLogError: (state, action) => {
            state.errorMessage = action.payload;
        },
    }
  })
  
  export const { 
    setIsLoading,
    setLogError,
    setUserbranchesList,
    setItemsPerPage,
    setFilters,
    setPage
  } = userbranchesSlice.actions;
  
  export default userbranchesSlice.reducer