import { createSlice } from '@reduxjs/toolkit';

import { makeRequest } from "../shared/common";

const endPoint = "venues";

export function fetchGetVenuesList(filters) {
    return async function fetchThunk(dispatch, getState) {
      dispatch(setIsLoading(true));
      await makeRequest(`${endPoint}/List`, 'post', { filters })
      .then((response) => {
        dispatch(setVenuesList(response.data));
        dispatch(setIsLoading(false));
      })
      .catch(err => {
        console.log('erre', err);
          dispatch(setLogError(err.message));
          dispatch(setIsLoading(false));
      });
    }
  }

  export function fetchDeleteVenues(venuesId) {
      return async function fetchThunk(dispatch, getState) {
          dispatch(setIsLoading(true));
        await makeRequest(`${endPoint}/Delete`, 'post',  {id: venuesId} )
        .then((response) => {
          dispatch(setIsLoading(false));
          return true;
        })
        .catch(err => {
            dispatch(setLogError(err.message));
            dispatch(setIsLoading(false));
        });
      }
    }

  export function fetchAddVenues(venuesData) {
    return async function fetchThunk(dispatch, getState) {
        dispatch(setIsLoading(true));
      await makeRequest(`${endPoint}/Create`, 'post', { data: venuesData })
      .then((response) => {
        dispatch(setIsLoading(false));
        return true;
      })
      .catch(err => {
          dispatch(setLogError(err.message));
          dispatch(setIsLoading(false));
      });
    }
  }

  export function fetchEditVenues(venuesData) {
    return async function fetchThunk(dispatch, getState) {
        dispatch(setIsLoading(true));
      await makeRequest(`${endPoint}/Update`, 'put', { data: venuesData })
      .then((response) => {
        dispatch(setIsLoading(false));
        return true;
      })
      .catch(err => {
          dispatch(setLogError(err.message));
          dispatch(setIsLoading(false));
      });
    }
  } 

export const venuesSlice = createSlice({
    name: 'venues',
    initialState: {
      venuesList: [],
      page:0,
      totalItems: 0,
      itemsPerPage: 20,
      isLoading: false,
      errorMessage: null,
      filters: {},
      order: null
    },
    reducers: {
        setVenuesList: (state, action) => {
            state.venuesList = action.payload.data.venuesList;
            if(action.payload.data.qty){
              state.totalItems = action.payload.data.qty;
            }
        },
        setItemsPerPage: (state, action) => {
          state.itemsPerPage = action.payload;
        },
        setFilters: (state, action) => {
          state.filters = action.payload;
        },
        setPage: (state, action) => {
          state.page = action.payload;
        },
        setOrder: (state, action) => {
          state.order = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setLogError: (state, action) => {
            state.errorMessage = action.payload;
        },
    }
  })
  
  export const { 
    setIsLoading,
    setLogError,
    setVenuesList,
    setItemsPerPage,
    setFilters,
    setPage,
    setOrder
  } = venuesSlice.actions;
  
  export default venuesSlice.reducer