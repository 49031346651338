import { createSlice } from '@reduxjs/toolkit';

import { makeRequest } from "../shared/common";

const endPoint = "categories";

export function fetchGetCategoriesList(filters) {
  return async function fetchThunk(dispatch, getState) {
    dispatch(setIsLoading(true));
    await makeRequest(`${endPoint}/List`, 'post', { filters })
      .then((response) => {
        dispatch(setCategoriesList(response.data));
        dispatch(setIsLoading(false));
      })
      .catch(err => {
        console.log('erre', err);
        dispatch(setLogError(err.message));
        dispatch(setIsLoading(false));
      });
  }
}

export function fetchDeleteCategories(categoriesId) {
  return async function fetchThunk(dispatch, getState) {
    dispatch(setIsLoading(true));
    await makeRequest(`${endPoint}/Delete`, 'post', { id: categoriesId })
      .then((response) => {
        dispatch(setIsLoading(false));
        return true;
      })
      .catch(err => {
        dispatch(setLogError(err.message));
        dispatch(setIsLoading(false));
      });
  }
}

export function fetchAddCategories(categoriesData) {
  return async function fetchThunk(dispatch, getState) {
    dispatch(setIsLoading(true));
    await makeRequest(`${endPoint}/Create`, 'post', { data: categoriesData })
      .then((response) => {
        dispatch(setIsLoading(false));
        return true;
      })
      .catch(err => {
        dispatch(setLogError(err.message));
        dispatch(setIsLoading(false));
      });
  }
}

export function fetchEditCategories(categoriesData) {
  return async function fetchThunk(dispatch, getState) {
    dispatch(setIsLoading(true));
    await makeRequest(`${endPoint}/Update`, 'put', { data: categoriesData })
      .then((response) => {
        dispatch(setIsLoading(false));
        return true;
      })
      .catch(err => {
        dispatch(setLogError(err.message));
        dispatch(setIsLoading(false));
      });
  }
}

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    categoriesList: [],
    page: 0,
    totalItems: 0,
    itemsPerPage: 20,
    isLoading: false,
    errorMessage: null,
    filters: {},
    order: null
  },
  reducers: {
    setCategoriesList: (state, action) => {
      state.categoriesList = action.payload.data.categoriesList;
      if (action.payload.data.qty) {
        state.totalItems = action.payload.data.qty;
      }
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setOrder: (state, action) => {
      state.order = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setLogError: (state, action) => {
      state.errorMessage = action.payload;
    },
  }
})

export const {
  setIsLoading,
  setLogError,
  setCategoriesList,
  setItemsPerPage,
  setFilters,
  setPage,
  setOrder
} = categoriesSlice.actions;

export default categoriesSlice.reducer